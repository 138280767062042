import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
export default function usePatientsList() {
    const tags_Total = ref(0);
    const tags_Data = ref([]);
    const group_Total = ref(0);
    const group_Data = ref([]);

    const fetchtag_patient  = () => { 
        tags_Data.value  = [];
        store.dispatch('app-patients/fetchTagData',{})
        .then(response => {
        let data = response.data.data;
        response.data.data.forEach((data) => data.value = data.id);
        response.data.data.forEach((data) => data.text = data.title);
        tags_Data.value  = response.data.data
        console.log('tags value',tags_Data.value);
        
        let Total = response.data.total?response.data.total: tags_Data.value.length;
           
        tags_Total.value = Total
        })
        .catch((error) => {
        console.log('error',error);
        })
    }

    const fetchgroup_patient  = () => { 
        group_Data.value  = [];
        store.dispatch('app-patients/fetchGroupData',{})
        .then(response => {
        let data = response.data.data;
        response.data.data.forEach((data) => data.value = data.id);
        response.data.data.forEach((data) => data.text = data.title);
        group_Data.value  = response.data.data
        console.log('group value',group_Data.value);
        
        let Total = response.data.total?response.data.total: group_Data.value.length;
           
        group_Total.value = Total
        })
        .catch((error) => {
        console.log('error',error);
        })
    }

    fetchtag_patient();
    fetchgroup_patient();
    return {
        fetchtag_patient,
        tags_Total,
        tags_Data,
        fetchgroup_patient,
        group_Total,
        group_Data,
    }
}